import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {
    MSAL_GUARD_CONFIG,
    MSAL_INSTANCE,
    MSAL_INTERCEPTOR_CONFIG,
    MsalBroadcastService,
    MsalGuard,
    MsalGuardConfiguration,
    MsalInterceptor,
    MsalInterceptorConfiguration,
    MsalService,
} from '@azure/msal-angular';
import { PublicClientApplication, InteractionType, BrowserCacheLocation, LogLevel } from '@azure/msal-browser';
import { AuthService } from '@port-line-up/shared/data-access';
import { environment } from '../environments';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

function loggerCallback(logLevel: LogLevel, message: string) {
    console.log(message);
}

function MSALInstanceFactory(): PublicClientApplication {
    return new PublicClientApplication({
        auth: {
            clientId: environment.b2cSettings.clientId,
            authority: environment.b2cSettings.signInAuthority,
            redirectUri: environment.baseUrl,
        },
        cache: {
            cacheLocation: BrowserCacheLocation.LocalStorage,
            storeAuthStateInCookie: isIE, // set to true for IE 11
        },
        system: {
            loggerOptions: {
                loggerCallback,
                logLevel: LogLevel.Error,
                piiLoggingEnabled: false,
            },
        },
    });
}

function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
    const protectedResourceMap = new Map<string, Array<string>>();
    protectedResourceMap.set(`${environment.baseApiUrl}/sharedLink?sharedLinkId=*`, null as any);
    protectedResourceMap.set(`${environment.baseApiUrl}/sharedLink/active`, null as any);
    protectedResourceMap.set(`${environment.baseApiUrl}/sharedLink/sailed`, null as any);
    protectedResourceMap.set(`${environment.baseApiUrl}/port/*/publicHolidays/anonymous`, null as any);
    protectedResourceMap.set(`${environment.baseApiUrl}/port/*/maintenances/anonymous`, null as any);
    protectedResourceMap.set(`${environment.baseApiUrl}/logger/logExcelExport/anonymous`, null as any);
    protectedResourceMap.set(`${environment.baseApiUrl}/portcalls/anonymous`, null as any);
    protectedResourceMap.set(`${environment.baseApiUrl}/vesselLocation/byUnlocode/*/anonymous`, null as any);

    protectedResourceMap.set(environment.baseApiUrl, environment.b2cSettings.scopes);

    return {
        interactionType: InteractionType.Redirect,
        protectedResourceMap,
    };
}

function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return {
        interactionType: InteractionType.Popup,
        authRequest: {
            scopes: environment.b2cSettings.scopes,
        },
    };
}

export const MSALProviders = [
    {
        provide: HTTP_INTERCEPTORS,
        useClass: MsalInterceptor,
        multi: true,
    },
    {
        provide: MSAL_INSTANCE,
        useFactory: MSALInstanceFactory,
    },
    {
        provide: MSAL_GUARD_CONFIG,
        useFactory: MSALGuardConfigFactory,
    },
    {
        provide: MSAL_INTERCEPTOR_CONFIG,
        useFactory: MSALInterceptorConfigFactory,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    AuthService,
];
