import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { APP_ENVIRONMENT } from '@port-line-up/shared/util';
import { EntityListResult, NotificationGroup, NotificationGroupPost } from '@port-line-up/shared/data-access';

@Injectable({providedIn: 'root'})
export class EmailNotificationGroupService {
    private readonly baseUrl = `${this.appEnvironment.baseApiUrl}/emailNotificationGroup`;

    constructor(private readonly http: HttpClient, @Inject(APP_ENVIRONMENT)
    private readonly appEnvironment: any) {}

    getAll(): Observable<EntityListResult<NotificationGroup>> {
        return this.http.get<EntityListResult<NotificationGroup>>(`${this.baseUrl}`);
    }

    getBy(notificationGroupId: string): Observable<NotificationGroup> {
        return this.http.get<NotificationGroup>(`${this.baseUrl}/${notificationGroupId}`);
    }

    save(notificationGroupPost: NotificationGroupPost): Observable<NotificationGroup> {
        return this.http.post<NotificationGroup>(`${this.baseUrl}`, notificationGroupPost);
    }

    delete(notificationGroupId: string): Observable<void> {
        return this.http.delete<void>(`${this.baseUrl}/${notificationGroupId}`);
    }
}
