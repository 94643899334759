import { GridFilter } from '@port-line-up/shared/util';
import { PortCallPresentation } from '@port-line-up/shared/feature-presentation-table';
import { Port } from './port';

export class SharedPreview {
    port: Port;
    activePortCalls: PortCallPresentation[] | null;
    sailedPortCalls: PortCallPresentation[] | null;
    filters: GridFilter;

    constructor(port: Port, activePortCalls: PortCallPresentation[] | null, sailedPortCalls: PortCallPresentation[] | null, filters: GridFilter) {
        this.port = port;
        this.activePortCalls = activePortCalls;
        this.sailedPortCalls = sailedPortCalls;
        this.filters = filters;
    }
}
